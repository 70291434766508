.react-calendar__navigation__label {
  pointer-events: none;
  user-select: none;
}

.btn-search {
  transition: 0.2s !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  text-transform: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 17px !important;
  font-weight: 500 !important;
}
.btn-rounded {
  border-radius: 5px !important;
  font-size: 17px !important;
  text-transform: none !important;
}
.btn-search.Mui-disabled {
  background-color: #d6dce4 !important;
}
.btn-search.Mui-disabled .MuiLoadingButton-loadingIndicator {
  color: #9ba9bd !important;
}
.btn-search.Mui-disabled .MuiLoadingButton-loadingIndicator span {
  width: 20px !important;
  height: 20px !important;
}

/* Classe per i giorni disponibili per un pernottamento */
.available-night-stay {
  background-color: red; /* Sfondo rosso per indicare che il giorno non è disponibile */
  color: white; /* Colore del testo bianco per il contrasto */
  /* border-radius: 5px; Rende il giorno rotondo */
  pointer-events: none; /* Disabilita l'interazione del mouse con questo giorno */
  opacity: 0.6; /* Riduce la visibilità per sembrare disabilitato */
}

.day-content {
  display: flex;
  align-items: baseline; /* Centra verticalmente */
  justify-content: center; /* Centra orizzontalmente */
}

.min-stay-text {
  font-size: 12px;
  text-align: center; /* Assicura che il testo sia centrato */
  min-width: 40px; /* Dimensione minima per restare sempre al centro */
}

.arrows-container {
  display: flex;
  align-items: center; /* Allinea le icone verticalmente */
  gap: 5px; /* Spaziatura tra le frecce */
}

.check-in-arrow,
.check-out-arrow {
  font-size: 11px;
  color: red;
  font-weight: 700;
  margin-bottom: 5px;
}
.restriction-check-in-out {
  font-size: 11px;
  color: red;
  font-weight: 700;
}

.min-stay-text {
  font-size: 11px; /* Dimensione del testo più piccola */
  /* color: #222;  */
}
.no-min-stay-text {
  margin-bottom: 17px;
}
.react-calendar {
  border: 0px solid;
}
.react-calendar__tile--range {
  background-color: #d3e5ff; /* Colore per le date all'interno dell'intervallo */
  color: #000; /* Colore del testo */
  border-radius: 0px; /* Angoli leggermente arrotondati */
}

/* Colore di sfondo per la data di inizio e fine */
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background-color: #3c84bc !important; /* Colore per la data di inizio e fine dell'intervallo */
  color: white;
  /* border-radius: 5px;  */
}
.react-calendar__month-view__weekdays__weekday {
  color: #666;
}
.react-calendar__navigation {
  color: #666;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #3c84bc;
}
.react-calendar__tile--range {
  background-color: #65b1f1;
  border-radius: 0px;
}
.ltr-1hety6i {
  position: relative;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: stretch flex-start;
  -webkit-box-pack: start;
  margin: 0px;
  overflow: auto;
  scrollbar-color: rgb(180, 184, 198) transparent;
  flex: 1 1 0%;
  padding: 0px 2rem;
}
.ltr-2nsdlj {
  position: relative;
  display: flex;
  flex-flow: row;
  -webkit-box-align: stretch;
  place-content: stretch flex-start;
  -webkit-box-pack: start;
  margin: 0px;
  padding: 0px;
  font-family: Roboto, sans-serif;
  align-items: flex-end;
}
.ltr-w2847c {
  position: relative;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: stretch flex-start;
  -webkit-box-pack: start;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
.ltr-u3n1l5 {
  position: relative;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: stretch flex-start;
  -webkit-box-pack: start;
  margin: 0px;
  padding: 0px;
  overflow: auto;
  scrollbar-color: rgb(180, 184, 198) transparent;
  color: rgb(80, 85, 101);
  max-height: inherit;
  height: inherit;
  width: 100%;
}
.ltr-13rjlio {
  position: relative;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: stretch flex-start;
  -webkit-box-pack: start;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  color: rgb(80, 85, 101);
  background-color: rgb(255, 255, 255);
}
.ltr-keqpif > * {
  pointer-events: auto;
}
.ltr-keqpif {
  font-family: Roboto, sans-serif;
  z-index: 90;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  cursor: auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  pointer-events: none;
}
.ltr-pf9h8z {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 100;
}
.ltr-epnzd2 {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1rem;
  color: #666;
  margin-bottom: 0.25rem;
  white-space: nowrap;
}
.ltr-1vo9pet {
  color: rgb(80, 85, 101);
  display: flex;
}
.ltr-199jhfg {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  place-content: flex-start;
  -webkit-box-pack: start;
  margin: 0px;
  padding: 0px;
}
.ltr-sgnors {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ltr-i28wbi {
  position: relative;
  flex-flow: column;
  align-items: flex-start;
  place-content: flex-start;
  -webkit-box-pack: start;
  padding: 0px;
  display: inline-block;
  width: 0px;
  height: 2rem;
  border-left: 1px solid rgb(220, 223, 229);
  margin: 0px 1rem;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
  text-decoration: none;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  width: auto;
}
