@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --backgroundPrimary: #7c85db;
  --backgroundWhite: #fff;
}

* {
  /* font-family: "Ubuntu", sans-serif; */
  font-family: "Inter", sans-serif;
}
html {
  min-height: 100%;
}
body {
  margin: 0;
  background: #f2f2f2;
  min-height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.bg-body {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.horizontal-bar {
  background-color: var(--backgroundWhite);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 10px;
}
.structure-details {
  display: flex;
  align-items: center;
}
.horizontal-bar .buttons {
  display: flex;
  align-items: center;
}
.language-box .MuiButton-textPrimary {
  font-weight: 700 !important;
}
.language-box .flag-selected {
  object-fit: cover;
  width: 30px;
  margin-right: 10px;
}
.flag-select {
  width: 30px;
  margin-right: 10px;
}
.horizontal-bar .buttons .action-button {
  text-transform: none;
  font-size: 14px;
  border-radius: 9999px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: inherit !important;
}
.button-icon {
  width: 24px;
  margin-right: 7px;
  vertical-align: middle;
}
.room-container {
}
.room {
  border-radius: 10px;
  height: auto;
  margin-bottom: 20px;
}
.room-variant {
  margin-right: 3px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  background-color: #fff !important;
  font-weight: 500 !important;
  font-family: inherit !important;
}
.room-panel {
  background-color: #fff !important;
  font-family: inherit !important;
  /* min-height: 280px !important; */
  border-radius: 5px;
}
.seeMore {
  position: relative !important;
  display: flex !important;
  flex-flow: row !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  place-content: flex-start center !important;
  margin: 0px !important;
  padding: 0px !important;
  outline: none !important;
  font-weight: 500 !important;
  border-style: solid !important;
  overflow: hidden !important;
  transition: 0.3s ease-in-out !important;
  border-width: 0px !important;
  color: #1565c0 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  -webkit-box-pack: center !important;
  cursor: pointer !important;
  background-color: white !important;
}

.flickity-page-dots .dot {
  width: 20px !important;
  height: 4px !important;
  margin: 0 4px !important;
  border-radius: 2px !important;
  background: #ffffff !important;
  -webkit-transition: width 0.2s ease !important;
  transition: width 0.2s ease !important;
}
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 10px !important;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}
.flickity-page-dots .dot.is-selected {
  width: 30px !important;
}
.flickity-viewport {
  border-radius: 5px !important;
  height: 200px;
}
.flickity-button {
  background: transparent !important;
  color: #fff !important;
}
.flickity-button-icon {
  fill: #fff !important;
}
.carousel-img {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 200px;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.carousel-img-cart {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 150px;
  height: 100px;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.room-name {
  margin: 0 !important;
  font-weight: 500 !important;
  color: rgb(44 44 44) !important;
  font-size: 20px !important;
  text-transform: uppercase !important;
  font-family: inherit !important;
}
.room-name-option {
  margin: 0 !important;
  font-weight: 500 !important;
  color: rgb(44 44 44) !important;
  font-size: 18px !important;
  text-transform: uppercase !important;
  font-family: inherit !important;
}
.ota-room {
  margin-right: 5px;
  margin-bottom: 5px;
}
.MuiChip-filledError.ota-room {
  background-color: rgb(255 92 92 / 23%) !important;
  color: rgb(255 0 0);
}
.MuiChip-filledSuccess.ota-room {
  background-color: rgb(79 251 73 / 30%) !important;
  color: rgb(0 139 23);
}
.room-details {
  width: 900px;
  color: #9b9b9b;
  margin-top: 10px !important;
  font-size: 13px !important;
}
@media only screen and (max-width: 600px) {
  .room-details {
    width: 100%;
  }
}
.room-details-guests {
  margin-right: 10px;
}
.room-details-icon {
  width: 15px;
  height: 15px;
}
.room-description {
  font-size: 0.875rem;
  margin-top: 4px !important;
  color: #4a4a4a;
}

@media only screen and (max-width: 600px) {
  .room-price::before {
    display: none;
  }
}
.room-price-skeleton::before {
  content: " ";
  width: 2px;
  border-left: 2px solid #ebebed;
  height: 20% !important;
  position: absolute;
}
.room-price-skeleton .start {
  margin: 0 !important;
}
.room-price-skeleton .main .price {
  margin-top: 45px !important;
  margin-bottom: 30px !important;
}

.room-price::before {
  /* content: ' ';
  width: 2px;
  border-left: 2px solid #EBEBED;
  height: 26%;
  position: absolute; */
}
.room-price .main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.room-price .main p {
  margin: 0 !important;
}
.room-price .main h1 {
  margin-top: 25px;
  margin-bottom: 30px !important;
}
.booknow {
  font-weight: 500 !important;
  font-family: inherit !important;
}
.room-details-mq-icon {
  margin-right: 5px !important;
}
.service-icon {
  color: #9b9b9b;
  width: 14px;
}
.service-icon-text {
  color: #3a3a3a;
  margin-left: 3px;
  font-size: 0.75rem !important;
  line-height: 1rem;
}

.searchbox-datepicker .react-datepicker,
.searchbox-datepicker .react-datepicker__header,
.searchbox-datepicker .react-datepicker__month-container {
  width: 100% !important;
}

.searchbox-datepicker .react-datepicker {
  border: none !important;
}
.searchbox-datepicker .react-datepicker__header {
  background-color: #fff !important;
  border: none !important;
}
.searchbox-datepicker .react-datepicker__day-name,
.searchbox-datepicker .react-datepicker__day,
.searchbox-datepicker .react-datepicker__time-name {
  width: 13% !important;
  line-height: 50px !important;
}
.searchbox-datepicker .react-datepicker__day {
  font-size: 16px !important;
  background-color: #ebebed;
  border-radius: 8px !important;
  position: relative;
  height: 50px;
}
.searchbox-datepicker .react-datepicker__day--range-start,
.searchbox-datepicker .react-datepicker__day--range-end {
  background-color: #205278 !important;
}
.searchbox-datepicker .react-datepicker__day .day-date {
  top: -12px;
  right: 5px;
}
.searchbox-datepicker .react-datepicker__day .span {
  position: absolute;
}
.searchbox-datepicker .react-datepicker__day-name {
  font-size: 16px;
  text-transform: capitalize;
}
.searchbox-datepicker .react-datepicker__current-month {
  text-transform: uppercase;
  font-size: 19px !important;
  font-weight: 500 !important;
}
.searchbox-datepicker
  .react-datepicker__day--disabled.react-datepicker__day--excluded {
  background-color: #ffd6d6;
  color: #000;
}
.searchbox-datepicker .react-datepicker__day span.dp-note {
  display: flex;
  width: 100%;
  height: 15px;
  line-height: 15px;
  bottom: 0px;
  color: white !important;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
}
.searchbox-datepicker .react-datepicker__day span.dp-note .min-stay {
  left: 35%;
  width: 30%;
  text-align: center;
  font-weight: 400;
}
.searchbox-datepicker .react-datepicker__day span.dp-note div {
  height: 100%;
  position: absolute;
  background-color: #205278 !important;
  border-radius: 9999px;
  width: 17px;
  font-size: 13px;
}
.searchbox-datepicker .react-datepicker__day--in-range {
  background-color: #3c84bc !important;
  color: #fff !important;
  font-weight: normal !important;
}
.searchbox-datepicker .react-datepicker__day--outside-month {
  background-color: #fff !important;
}
.searchbox-datepicker .react-datepicker__day--outside-month {
  color: rgb(120 120 120) !important;
}
.searchbox-datepicker .search-label-form {
  font-weight: 500 !important;
  margin-bottom: 5px !important;
  margin-left: 15px;
  display: flex;
}
.searchbox-datepicker .react-datepicker__day--selected {
  background-color: #3c84bc !important;
}
.MuiModal-root {
  position: relative;
  z-index: 1050; /* Adatta a seconda del valore di z-index della tua modale */
}

/* CSS per il Lightbox */
.ReactModal__Overlay {
  z-index: 1300 !important; /* Maggiore di quello della modale */
}
.btn-primary {
  transition: 0.2s !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  text-transform: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 17px !important;
  font-weight: 500 !important;
}
.btn-rounded {
  border-radius: 50px !important;
  font-size: 17px !important;
  text-transform: none !important;
}
.btn-primary:hover {
}

.btn-primary.Mui-disabled {
  background-color: #d6dce4 !important;
}
.btn-primary.Mui-disabled .MuiLoadingButton-loadingIndicator {
  color: #9ba9bd !important;
}
.btn-primary.Mui-disabled .MuiLoadingButton-loadingIndicator span {
  width: 20px !important;
  height: 20px !important;
}
.MuiLoadingButton-loadingIndicator {
  color: white !important;
}
.promo-icon {
  width: 24px;
  color: #9b9b9b;
  transform: rotate(-45deg);
}
.css-MuiOutlinedInput-input {
  padding: 4.5px 14px;
}

.rounded-input div:first-child {
  border-radius: 5px !important;
}
.sponsor {
  width: 128px;
  margin-top: 20px;
}
.calendar {
  margin: 0.25em 10px 10px 0;
  padding-top: 5px;
  float: left;
  width: 80px;
  background: #ededef;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ededef),
    to(#ccc)
  );
  background: -moz-linear-gradient(top, #ededef, #ccc);
  font: bold 30px/60px Arial Black, Arial, Helvetica, sans-serif;
  text-align: center;
  color: #000;
  text-shadow: #fff 0 1px 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  -moz-box-shadow: 0 2px 2px #888;
  -webkit-box-shadow: 0 2px 2px #888;
  box-shadow: 0 2px 2px #888;
}
.calendar em {
  display: block;
  font: normal bold 11px/30px Arial, Helvetica, sans-serif;
  color: #fff;
  text-shadow: #00365a 0 -1px 0;
  background: #04599a;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#04599a),
    to(#00365a)
  );
  background: -moz-linear-gradient(top, #04599a, #00365a);
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #00365a;
}
.calendar:before,
.calendar:after {
  content: "";
  float: left;
  position: absolute;
  top: 5px;
  width: 8px;
  height: 8px;
  background: #111;
  z-index: 1;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 0 1px 1px #fff;
  -webkit-box-shadow: 0 1px 1px #fff;
  box-shadow: 0 1px 1px #fff;
}
.calendar:before {
  left: 11px;
}
.calendar:after {
  right: 11px;
}
.calendar em:before,
.calendar em:after {
  content: "";
  float: left;
  position: absolute;
  top: -5px;
  width: 4px;
  height: 14px;
  background: #dadada;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f1f1),
    to(#aaa)
  );
  background: -moz-linear-gradient(top, #f1f1f1, #aaa);
  z-index: 2;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.calendar em:before {
  left: 13px;
}
.calendar em:after {
  right: 13px;
}
.calendar-label {
  margin: 0 !important;
  text-align: center;
}
.calendar-label.nights {
  margin-top: 160px !important;
}
.calendar {
  margin: 0 !important;
  margin-top: 15px !important;
}

#moon {
  width: 60px;
  height: 60px;
  left: 60px;
  top: -50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  box-shadow: 15px 7px 0 0 #efdd77;
  -webkit-transform: rotate(130deg);
  -moz-transform: rotate(130deg);
  -ms-transform: rotate(130deg);
  -o-transform: rotate(130deg);
  transform: rotate(130deg);
  animation: moonSwing 3s infinite linear;
}

#star1 {
  top: 0px;
  animation: starFadeIn 5s infinite linear;
}

#star2 {
  left: 30px;
  top: -100px;
  animation: starFadeOut 6s infinite linear;
}

#star3 {
  left: 10px;
  top: -129px;
  animation: starFadeIn 7s infinite linear;
}
.weather .star {
  position: relative;
  display: block;
  width: 0px;
  height: 0px;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 70px solid #efdd77;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  -webkit-transform: scale(0.15);
  -moz-transform: scale(0.15);
  -ms-transform: scale(0.15);
  -o-transform: scale(0.15);
  transform: scale(0.15);
}
.weather .star:before {
  content: "";
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 80px solid #efdd77;
  position: absolute;
  height: 0;
  width: 0;
  top: -45px;
  left: -60px;
  display: block;
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  transform: rotate(-35deg);
}
.weather .star:after {
  content: "";
  position: absolute;
  display: block;
  top: 3px;
  left: -105px;
  width: 0px;
  height: 0px;
  border-right: 100px solid transparent;
  border-bottom: 70px solid #efdd77;
  border-left: 100px solid transparent;
  -webkit-transform: rotate(-70deg);
  -moz-transform: rotate(-70deg);
  -ms-transform: rotate(-70deg);
  -o-transform: rotate(-70deg);
  transform: rotate(-70deg);
}

@keyframes moonSwing {
  0% {
    -webkit-transform: rotate(125deg);
    -moz-transform: rotate(125deg);
    -ms-transform: rotate(125deg);
    -o-transform: rotate(125deg);
    transform: rotate(125deg);
  }
  50% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    -webkit-transform: rotate(125deg);
    -moz-transform: rotate(125deg);
    -ms-transform: rotate(125deg);
    -o-transform: rotate(125deg);
    transform: rotate(125deg);
  }
}
@keyframes starFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes starFadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.loading-indicator {
  width: 20px !important;
  height: 20px !important;
}
.room-box {
  border-right: 2px solid #ebebed;
}

.room-details {
  padding: 0 !important;
}
.room-details .header {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 16px !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.room-details .header h2 {
  margin: 0 !important;
  font-weight: 500 !important;
}
.room-details .content {
  max-height: 600px;
  overflow-y: auto;
}
.input-icon {
  width: 24px;
  color: #94a3b8;
}
.trash-icon {
  width: 20px;
  color: #94a3b8;
}
.room-details .footer {
  box-shadow: rgba(0, 0, 0, 0.24) 0px -3px 8px;
  padding: 16px !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: auto;
  padding-bottom: 30px !important;
}
.room-details .footer .container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 0;
}
.room-details .footer .recap-icon {
  width: 20px !important;
  height: 20px !important;
}
.room-details .footer .recap-icon-small {
  width: 16px !important;
  height: 16px !important;
}
.room-details .footer .adults {
  font-size: 16px;
  font-weight: 600 !important;
  margin: 0 !important;
}
.room-details .content .description {
  font-weight: 700 !important;
  color: #4a4a4a !important;
}
.cart-fav {
  position: sticky !important;
  bottom: 10px !important;
  left: 95%;
  width: 70px !important;
  height: 70px !important;
  z-index: 999 !important;
}
.cart-icon {
  color: #fff;
  width: 40px;
}
.tax-box::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

/* Track */
.tax-box::-webkit-scrollbar-track {
  background: #e7e7e7;
}

/* Handle */
.tax-box::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 99px;
}
.summary-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.summary-dates {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  background-color: #f9f9f9;
  position: relative;
}
.summary-box-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #f9f9f9;
  position: relative;
}
.summary-date-left,
.summary-date-right {
  width: 50%;
  padding: 10px;
}
.summary-date-left {
  border-right: 1px solid #e5e5e5;
}
.summary-date-right {
  padding-left: 10px;
}
.summary-date-header {
  text-transform: uppercase;
  color: #878787;
  font-size: 15px;
  font-weight: 500;
}
.summary-date-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.summary-date-container h2 {
  font-size: 44px;
  font-weight: 400;
  margin: 0;
  color: #3f4042;
  line-height: 53px;
}
.summary-date-text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.summary-date-day {
  text-transform: capitalize;
  font-weight: 500;
  color: #3f4042;
}
.summary-date-month {
  text-transform: uppercase;
  color: #333;
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 10px;
}
.summary-date-year {
  color: #333;
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 10px;
}
.summary-date-arrow {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.summary-date-arrow .arrow-icon {
  height: 40px;
  width: 20px;
  background-color: #f9f9f9;
  color: #222222;
  justify-content: center;
  align-items: center;
  display: flex;
}
.summary-date-arrow .arrow-icon img {
  height: 16px;
}
.summary-down-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid #e5e5e5;
}
.summary-down-box-container {
  width: 33%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e5e5e5;
}
.summary-down-box-container:last-child {
  border-right: none !important;
}
.summary-down-box-container .recap-icon-small {
  font-size: 27px;
  color: #7a7a7a;
}
.summary-down-box-text-number {
  font-size: 22px;
  vertical-align: middle;
  color: #444444;
}
.summary-down-box-text-label {
  margin-left: 5px;
  text-transform: uppercase;
  vertical-align: middle;
  color: #444444;
  font-weight: 500;
}
.payment-day-row {
  display: flex;
  flex-direction: row;
  background-color: #fff !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.payment-day-row-container {
  min-height: 20px;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  overflow-x: auto;
}
.payment-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  min-width: 90px;
}
.payment-day-row-container::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}
.payment-day-row-container::-webkit-scrollbar-track {
  background: #e7e7e7;
}
.payment-day-row-container::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 99px;
}
.payment-day-row-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.extra-scroll {
  margin-right: 10px;
}
.extra-scroll::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}
.extra-scroll::-webkit-scrollbar-track {
  background: #e7e7e7;
}
.extra-scroll::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 99px;
}
.extra-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.payment-day-box {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.payment-day-text {
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
  margin-right: auto;
}
.payment-day-box-price {
  font-weight: 500 !important;
  font-size: 19px;
  color: #1976d2;
  margin-top: 5px;
}
.payment-day-box-day-month {
}
.fixed-costs {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.fixed-cost-title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}
.fixed-cost-description {
  margin: 0;
  font-size: 13px;
  color: #838383;
}
.fixed-cost-container {
  margin-left: 0px;
}
.fixed-cost-price {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}
.extra {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #ebebed;
}
.extra-col {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 600px) {
  .extra-container {
    flex-direction: column !important;
  }
  .extra-title {
    text-align: center !important;
  }
  .extra-col {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    gap: 10px !important;
  }
}
.extra:last-child {
  border-bottom: none !important;
}
.fixed-costs-img {
  width: 150px;
  border-radius: 10px;
  object-fit: cover;
}
.extra-img {
  width: 150px;
  border-radius: 10px;
  margin-left: 20px;
  object-fit: cover;
}
.extra-title {
  font-weight: 500;
  font-size: 19px;
  margin: 0;
  margin-bottom: 8px;
  color: #323e48;
}
.extra-description {
  margin: 0;
  margin-left: 15px;
}

.extra-container {
  display: flex;
  flex-direction: row;
}
.extra-check {
  display: flex;
  justify-content: center;
  align-items: center;
}
.extra-price {
  font-size: 20px;
  font-weight: 500;
}
.extra-qty-select {
  padding: 5px;
  border: 1px solid #adadad !important;
  border-radius: 3px;
  font-size: 16px;
}
.extra-footer {
  border-top: 1px solid #ebebed;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.extra-footer-label {
  font-size: 20px;
  margin: 0;
  color: #323e48;
}
.extra-footer-total {
  margin: 0;
  margin-left: auto;
  font-size: 28px;
  font-weight: 500;
}
.MuiFormHelperText-root.Mui-error {
  margin: 0 !important;
  margin-top: 5px !important;
}
.card-form__inner {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
}
.card-input {
  margin-right: 25px !important;
}
.card-item__dateItem {
  margin-right: 10px !important;
}
.card-input__input.-select {
  height: 62px !important;
}
.fake-link {
  text-decoration: underline;
  color: #04599a;
}
.d-lb {
  white-space: pre-line;
}
.confirmed-title {
  font-weight: 800;
  color: rgb(60, 62, 99);
  font-size: 1.6rem;
  margin-bottom: 0;
}
.fs-1 {
  font-size: 1.3rem !important;
  color: #5f6295 !important;
}
.d-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  margin-bottom: 20px;
}
.d-row p {
  margin: 0;
  margin-left: 10px;
}
.check-mark {
  width: 24px;
  height: 20px;
}
.confirmed-img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}
.details-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 3px;
  font-size: 18px;
  margin-bottom: 0;
  width: 100%;
}
.first-container {
  border-bottom: 0 !important;
  border-bottom-left-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}
.second-container {
  border-bottom: 0 !important;
  border-radius: 0 !important;
}
.last-container {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom: 1px solid #ddd !important;
}
.ml-auto {
  margin-left: auto !important;
}
.details-table-value {
  color: #305270 !important;
}
.booking-price {
  margin-top: 20px;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #d5dffa;
  background-color: #e9f0fa;
  width: 100%;
  margin-bottom: 30px;
}
.booking-price td:first-child {
  font-weight: bold;
  color: #545454;
}
.booking-price td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 15px;
}
.booking-price td:last-child {
  text-align: right;
}
.price {
  font-size: 22px !important;
  font-weight: 700 !important;
}
.confirmed-subtitle {
  margin: 0;
  font-size: 13px;
  color: #858585;
  margin-top: 5px !important;
  margin-bottom: 30px !important;
}
.mb-3 {
  margin-bottom: 10px;
}
.discount {
  margin: 0px !important;
  color: rgba(255, 0, 0, 0.5) !important;
  text-decoration: none !important;
  position: relative !important;
  font-size: 21px !important;
}
.discount-price {
  margin-top: 0px !important;
  color: rgb(7, 178, 7);
  font-size: 26px;
  font-weight: 500;
}
.discount:before {
  content: " ";
  display: block;
  width: 100%;
  border-top: 2px solid rgba(255, 0, 0, 0.8);
  height: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(-7deg);
}
.selected_room_price {
  font-size: 14px !important;
  color: #aaabad;
  font-weight: bolder;
  text-decoration: line-through;
  margin-bottom: -5px;
}
.booking-price-discount {
  font-size: 20px !important;
  color: red !important;
}
.tag-discount__label {
  position: absolute;
  font-size: 0.9em;
  margin-left: -12px;
  text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff,
    0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff,
    0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff;
  color: red;
  font-weight: bold;
  width: 2em;
  text-align: left;
}
.leggibile {
  font-family: consolas;
}
.dropzone {
  text-align: center;
}
.dropzone-container {
  padding: 10px;
  border: 2px dashed #3198e7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.dropzone-btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}
.dropzone-btn:hover {
  background-color: blue;
  color: aliceblue;
  cursor: pointer;
}
.loaded-file,
.loaded-file h3,
.loaded-file p {
  text-align: center;
  background-color: white !important;
  color: green !important;
}
.ios-scroll::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}
.ios-scroll::-webkit-scrollbar:hover {
}
.ios-scroll::-webkit-scrollbar-track {
  background: #e7e7e7;
}
.ios-scroll::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 99px;
}
.ios-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.MuiInputBase-input:focus {
  border-color: inherit !important;
  box-shadow: none !important;
}
.cart-tag .ant-tag-close-icon {
  font-size: 14px !important;
}

.wpwl-form {
  margin-left: 0;
}
.wpwl-button.wpwl-button-pay {
  background-color: #1677ff !important;
  border-color: #1677ff !important;
  color: #fff !important;
  transition: 0.2s;
}
.wpwl-button.wpwl-button-pay:hover {
  background-color: #4096ff !important;
  border-color: #4096ff !important;
  color: #fff !important;
}
.ant-select-selection-search-input {
  box-shadow: none !important;
}
.configs-table tr {
  border-bottom: 1px solid #ddd;
}
.configs-table th {
  border-bottom: 1px solid #b9b9b9;
}
.configs-table td,
th {
  padding: 15px;
  text-align: left;
}

.bannerUpload .wrapper {
  width: 100%;
}
.bannerUpload .drop_zone {
  display: flex;
  height: 200px;
  width: 100%;
  background: white;
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  background-color: #ffffff;
  cursor: pointer;
  border: 2px dashed #ccc;
  border-radius: 6px;
  margin-bottom: 25px;
  background-position: center 28px;
}
.bannerUpload .drop_zone > p {
  margin: auto;
}
.bannerUpload .image {
  width: 100%;
}
.hide-dp.ant-picker {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  position: absolute;
}

.ant-picker-dropdown .ant-btn-primary:disabled,
.ant-popconfirm-buttons .ant-btn-primary:disabled,
.ant-drawer-footer .ant-btn-primary:disabled,
.ant-modal-footer .ant-btn-primary:disabled,
.ant-modal-confirm-btns .ant-btn-primary:disabled,
.ant-modal-body .ant-btn-primary:disabled,
.custom .ant-btn-primary:disabled,
.ant-alert-action .ant-btn-primary:disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.ant-picker-dropdown .ant-btn-primary:disabled:hover,
.ant-popconfirm-buttons .ant-btn-primary:disabled:hover,
.ant-drawer-footer .ant-btn-primary:disabled:hover,
.ant-modal-footer .ant-btn-primary:disabled:hover,
.ant-modal-confirm-btns .ant-btn-primary:disabled:hover,
.ant-modal-body .ant-btn-primary:disabled:hover,
.custom .ant-btn-primary:disabled:hover,
.ant-alert-action .ant-btn-primary:disabled:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.ant-picker-dropdown .ant-btn-primary,
.ant-popconfirm-buttons .ant-btn-primary,
.ant-drawer-footer .ant-btn-primary,
.ant-modal-footer .ant-btn-primary,
.ant-modal-confirm-btns .ant-btn-primary,
.ant-modal-body .ant-btn-primary,
.custom .ant-btn-primary,
.ant-alert-action .ant-btn-primary {
  background-color: #1677ff !important;
}
.ant-picker-dropdown .ant-btn-primary:hover,
.ant-popconfirm-buttons .ant-btn-primary:hover,
.ant-drawer-footer .ant-btn-primary:hover,
.ant-modal-footer .ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-body .ant-btn-primary:hover,
.custom .ant-btn-primary:hover,
.ant-alert-action .ant-btn-primary:hover {
  background-color: #4096ff !important;
}

.ant-modal-footer .ant-btn-dangerous {
  background-color: #ff4d4f !important;
}
.ant-modal-footer .ant-btn-dangerous:hover {
  background-color: #ff7875 !important;
}
.ant-popover-content p {
  margin: 0;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.quill {
  position: relative !important;
  height: auto !important;
}
.ql-container {
  height: 100px !important;
  width: 100% !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.brcr li {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.full-content .ant-typography-edit-content {
  width: 100% !important;
}
.payment .ant-modal-content {
  padding: 0 !important;
}
.phone-input .form-control {
  width: 100% !important;
}
.checkout .ant-collapse-content-box {
  padding-top: 0 !important;
}
.checkout .ant-collapse-header {
  padding-bottom: 0 !important;
}
.max-w-8xl {
  max-width: 85rem;
}
.anticon.anticon-edit {
  font-size: 20px;
}
.form-w-full .ant-typography-edit-content {
  width: 100% !important;
}
.ant-steps-item-title,
.ant-steps-item-icon,
.ant-steps-icon {
  color: #fff !important;
}
.ant-steps-item-title::after {
  background-color: #777777 !important;
}
.ant-steps-item-icon {
  background-color: #63636379 !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #1677ffa1 !important;
  border-color: #1677ffa1 !important;
}
.ios-scroll::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}
/* Track */
.ios-scroll::-webkit-scrollbar-track {
  background: #90909033;
}
/* Handle */
.ios-scroll::-webkit-scrollbar-thumb {
  background: rgb(227, 227, 227);
  border-radius: 99px;
}
/* Handle on hover */
.ios-scroll::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}
.white-children * {
  color: white !important;
}
/* .packet-datepicker .react-datepicker__navigation-icon::before {
  color: #fff !important;
  border-color: #fff !important;
}
.react-datepicker__day {
  border-radius: 9999px !important;
}
.packet-datepicker .react-datepicker__day,
.packet-datepicker .react-datepicker__day-name {
  padding: 5px !important;
  width: 2.3rem !important;
}
.packet-datepicker .react-datepicker__day--in-selecting-range {
  background-color: #f1f1f1 !important;
  border-radius: 0px !important;
  color: #b0b0b0 !important;
}

.packet-datepicker .react-datepicker__header,
.packet-datepicker .react-datepicker__current-month,
.packet-datepicker .react-datepicker__day-names .react-datepicker__day-name {
  background-color: #3f9ae9 !important;
  color: #fff !important;
}
.packet-datepicker .react-datepicker__day--selected {
  border-radius: 9999px !important;
  background-color: #3f9ae9 !important;
}
.packet-datepicker .react-datepicker__header {
  padding: 20px !important;
}
.packet-datepicker .react-datepicker__navigation {
  top: 20px !important;
}
.packet-datepicker .react-datepicker {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.packet-datepicker .react-datepicker__input-container input {
  border: 1px solid #cbcbcb !important;
  border-radius: 8px !important;
}
.packet-datepicker .react-datepicker__input-container input:hover {
  border: 1px solid #b5b5b5 !important;
}
.packet-datepicker .react-datepicker__input-container input:focus {
  border: 1px solid #6587f5 !important;
}

.packet-datepicker .react-datepicker {
  position: absolute !important;
} */
.packet-datepicker .react-datepicker__navigation-icon::before {
  color: #fff !important;
  border-color: #fff !important;
}
.react-datepicker__day {
  border-radius: 9999px !important;
}
.packet-datepicker .react-datepicker__day,
.packet-datepicker .react-datepicker__day-name {
  padding: 5px !important;
  width: 2.3rem !important;
}
.packet-datepicker .react-datepicker__day--in-selecting-range {
  background-color: #f1f1f1 !important;
  border-radius: 0px !important;
  color: #b0b0b0 !important;
}

.packet-datepicker .react-datepicker__header,
.packet-datepicker .react-datepicker__current-month,
.packet-datepicker .react-datepicker__day-names .react-datepicker__day-name {
  background-color: #3f9ae9 !important;
  color: #fff !important;
}
.packet-datepicker .react-datepicker__day--selected {
  border-radius: 9999px !important;
  background-color: #3f9ae9 !important;
}
.packet-datepicker .react-datepicker__header {
  padding: 20px !important;
}
.packet-datepicker .react-datepicker__navigation {
  top: 20px;
}
.packet-datepicker .react-datepicker {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.packet-datepicker .react-datepicker__input-container input {
  border: 1px solid #cbcbcb;
  border-radius: 8px;
}
.packet-datepicker .react-datepicker__input-container input:hover {
  border: 1px solid #b5b5b5;
}
.packet-datepicker .react-datepicker__input-container input:focus {
  border: 1px solid #6587f5;
}

.packet-datepicker .react-datepicker {
  position: absolute !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem !important;
  padding: 20px !important;
  background-color: #3f9ae9 !important;
  color: #fff !important;
}
.react-datepicker__day-names {
  white-space: nowrap !important;
  margin-bottom: -8px !important;
  color: #fff !important;
}
.react-datepicker__day-name {
  color: #ffffff !important;
  display: inline-block !important;
  width: 1.7rem !important;
  line-height: 1.7rem !important;
  text-align: center !important;
  margin: 0.166rem !important;
  padding: 5px !important;
  width: 2.3rem !important;
}
.react-datepicker__day {
  color: #ffffff !important;
  display: inline-block !important;
  width: 1.7rem !important;
  line-height: 1.7rem !important;
  text-align: center !important;
  margin: 0.166rem !important;
  padding: 5px !important;
  width: 2.3rem !important;
}
.react-datepicker__day--in-range {
  color: #ffffff !important;
}
.react-datepicker__current-month {
  margin-top: 0;
  color: #ffffff !important;
  font-weight: bold;
  font-size: 0.944rem;
}
.react-datepicker__day
  .react-datepicker__day--disabled
  .react-datepicker__day--outside-month {
  cursor: default !important;
  color: #ccc !important;
}
.react-datepicker__navigation-icon {
  position: relative;
  top: 11px;
  color: #fff;
}
.react-datepicker__week .react-datepicker__day {
  color: #000 !important;
}
.react-datepicker-ignore-onclickoutside:hover {
  border: 1px solid #b5b5b5;
}
.react-datepicker-ignore-onclickoutside {
  border: 1px solid #cbcbcb !important;
  border-radius: 8px !important;
}
.react-datepicker__input-container input {
  border: 1px solid #cbcbcb !important;
  border-radius: 8px !important;
}
