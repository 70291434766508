.prices {
  :root {
    --primary: #2b5cdf;
    --secondary: #858585;
    --background-primary: #f1f5ff;
    --text-primary: #1e293b;
    --text-secondary: #7d8a9d;
  }
  /* @extend display-flex; */
  display-flex,
  .signup-content,
  .actions ul,
  .actions ul li a {
    display: flex;
    display: -webkit-flex;
  }

  /* @extend list-type-ulli; */
  list-type-ulli,
  .actions ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a:focus,
  a:active {
    text-decoration: none;
    outline: none;
    transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -webkit-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s;
  }

  input,
  select,
  textarea {
    outline: none;
    appearance: unset !important;
    -moz-appearance: unset !important;
    -webkit-appearance: unset !important;
    -o-appearance: unset !important;
    -ms-appearance: unset !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    margin: 0;
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    -ms-box-shadow: none !important;
  }

  input[type="checkbox"] {
    appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    -webkit-appearance: checkbox !important;
    -o-appearance: checkbox !important;
    -ms-appearance: checkbox !important;
  }

  input[type="radio"] {
    appearance: radio !important;
    -moz-appearance: radio !important;
    -webkit-appearance: radio !important;
    -o-appearance: radio !important;
    -ms-appearance: radio !important;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0 30px transparent inset;
    -moz-box-shadow: 0 0 0 30px transparent inset;
    -webkit-box-shadow: 0 0 0 30px transparent inset;
    -o-box-shadow: 0 0 0 30px transparent inset;
    -ms-box-shadow: 0 0 0 30px transparent inset;
    background-color: transparent !important;
  }

  .clear {
    clear: both;
  }

  h2 {
    font-size: 27px;
    color: #222;
    font-weight: 800;
    margin: 0px;
    padding-bottom: 65px;
  }
  h2 span {
    color: #7c7cdd;
  }

  body {
    font-size: 13px;
    line-height: 1.6;
    color: #f2f2f2;
    font-weight: 400;
    margin: 0px;
    background: #f2f2f2;
  }

  .main {
    padding: 50px 0;
    z-index: 999;
  }

  .container {
    width: 1400px;
    position: relative;
    margin: 0 auto;
    background: var(--background-primary);
    z-index: 999;
  }

  p {
    margin: 0px;
  }

  p.desc {
    padding-bottom: 330px;
    color: #999;
    line-height: 1.92;
  }

  p.title {
    font-weight: bold;
    padding-bottom: 7px;
  }

  .signup-img {
    width: 30%;
    margin-top: auto;
  }

  fieldset {
    border: none;
    padding: 0px;
    margin: 0px;
  }

  .signup-desc {
    background: #fff;
    width: 495px;
    z-index: 999;
    padding: 20px;
  }

  .signup-desc-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signup-form-conent {
    width: 905px;
  }
  .signup-form-conent > div:first-child,
  .rsw_2Y,
  .rsw_2f {
    height: 100%;
  }

  .signup-form {
    padding: 55px 110px;
  }

  input {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #bfbfbf;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 24px;
    transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    padding-top: 30px;
    padding-bottom: 5px;
    -webkit-appearance: none;
    background: transparent;
  }
  input + label {
    position: absolute;
    transition: 0.25s ease;
    -moz-transition: 0.25s ease;
    -webkit-transition: 0.25s ease;
    -o-transition: 0.25s ease;
    -ms-transition: 0.25s ease;
    left: 0;
    top: 25px;
    font-size: 24px;
    font-weight: bold;
    color: #888;
  }
  input:focus + label {
    top: -7px;
    font-size: 14px;
    font-weight: 500;
  }
  input:valid + label {
    top: -7px;
    font-size: 14px;
    font-weight: 500;
  }

  .form-group {
    position: relative;
  }

  .field-icon {
    font-size: 24px;
    position: relative;
    z-index: 2;
    float: right;
    margin-top: -37px;
  }

  .content {
    padding: 340px 0 315px 0;
    position: relative;
  }
  .content h3 {
    display: none;
  }

  .step-current {
    position: absolute;
    right: 0;
    top: 0;
    text-transform: uppercase;
    font-weight: 800;
    color: #7c7cdd;
  }

  .actions ul {
    width: 100%;
    justify-content: flex-end;
    -moz-justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -o-justify-content: flex-end;
    -ms-justify-content: flex-end;
  }
  .actions ul .disabled {
    display: none;
  }
  .actions ul li {
    margin-left: 20px;
  }
  .actions ul li:first-child a {
    background: #fff;
    color: #999;
  }
  .actions ul li a,
  .wbutton {
    padding: 15px !important;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: bold !important;
    background: #7c7cdd !important;
    align-items: center !important;
    -moz-align-items: center !important;
    -webkit-align-items: center !important;
    -o-align-items: center !important;
    -ms-align-items: center !important;
    justify-content: center !important;
    -moz-justify-content: center !important;
    -webkit-justify-content: center !important;
    -o-justify-content: center !important;
    -ms-justify-content: center !important;
    text-decoration: none !important;
    width: 100px !important;
    text-align: center !important;
    text-transform: none !important;
  }
  .wbutton.previous {
    color: #999999;
    background: #fff;
  }

  .steps {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .container {
      width: calc(100% - 40px);
      max-width: 100%;
    }

    .signup-form {
      padding: 55px 30px;
    }

    .signup-desc {
      width: 465px;
    }

    .signup-img {
      width: 40%;
    }
  }
  @media screen and (max-width: 768px) {
    .steps ul,
    .signup-content {
      flex-direction: column;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      -o-flex-direction: column;
      -ms-flex-direction: column;
    }

    .steps ul li,
    .signup-desc,
    .signup-form-conent {
      width: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    .actions ul li a {
      width: 100px;
    }
  }

  /*# sourceMappingURL=style.css.map */

  .signup-content {
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;
    min-height: 600px;
  }

  .text-primary {
    color: var(--primary);
  }
  .subtitle-description {
    color: var(--secondary);
    font-size: 1rem;
  }
  .wizard-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-left: auto;
    margin-bottom: 20px;
    margin-right: 20px;
    gap: 10px;
  }
  .step-wiz {
    display: flex;
    flex-direction: column-reverse;
  }
  .img1 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .custom-input-label {
    font-size: 15px;
    color: #000;
  }
  .custom-input {
    width: 100%;
    border: 2px solid #ddd;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    color: rgb(56, 56, 56);
    font-size: 18px;
    font-weight: 600;
  }
  .custom-input::placeholder {
    color: #d5d5d5;
  }
  .MuiAccordionSummary-root {
    border: 2px solid #ddd !important;
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: none !important;
  }
  .MuiCollapse-hidden {
    box-shadow: none !important;
  }
  .switch-label {
    color: var(--text-primary) !important;
    font-weight: 700 !important;
    font-size: 20px !important;
  }

  .form-control {
    font-family: system-ui, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
  }

  .form-control + .form-control {
    margin-top: 1em;
  }

  .form-control:focus-within {
    color: var(--form-control-color);
  }

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    -moz-appearance: none;
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--background-primary);
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.55em;
    height: 0.55em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary);
    /* Windows High Contrast Mode */
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }
  .input-file {
    border: none !important;
    font-size: 20px;
    color: var(--text-primary);
    margin-bottom: 20px;
  }

  input[type="text"],
  select {
    height: 51px;
  }
  .rooms-selected {
    color: var(--text-primary) !important;
  }
  .rooms-selected * {
    margin: 0 !important;
  }
  .rooms-selected h4 {
    margin-right: 10px !important;
  }
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}
.react-confirm-alert-body p {
  font-size: 20px;
}
